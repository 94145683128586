import Swal from 'sweetalert2'
import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";
import { Modal } from 'bootstrap';
import $ from 'jquery'

export default class extends Controller {
  static values = {
    message: String,
    url: String,
    view: String,
    method: String,
    modal: String,
    input: String
  }

  static targets = ["modal"]

  show(event) {
    const message = this.messageValue;
    event.stopImmediatePropagation()
    event.preventDefault()
    Swal.fire({
      title: message,
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if(result.isConfirmed) {
        get(this.urlValue, {
          responseKind: "turbo-stream"
        })
      }
    })
  }

  confirm(event) {
    const modal = new Modal(this.modalTarget)

    const message = this.messageValue;
    event.stopImmediatePropagation()
    event.preventDefault()
    Swal.fire({
      title: message,
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if(result.isConfirmed) {
        modal.show()
      }
    })
  }

  move(event) {
    const message = this.messageValue;
    event.stopImmediatePropagation()
    event.preventDefault()
    Swal.fire({
      title: message,
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Inactivate',
      confirmButtonText: 'Move',
    }).then((result) => {
      if(result.isConfirmed) {
        let moveModal = new Modal($(this.modalValue));
        moveModal.show();
      }
      else if (
        result.dismiss === Swal.DismissReason.cancel) {
        get(this.urlValue, {
          responseKind: "turbo-stream"
        })
      }
    })
  }

  change(event) {
    const status = document.getElementById('status-select')
    let params = new URLSearchParams
    params.append('status', status.value)
    params.append('view', this.viewValue)

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream",
      data: params
    })
    this.addDepartmentFilterSelect(status.value)
  }

  addDepartmentFilterSelect(status) {
    var department_list = document.getElementById('departments-list')
    var forms = department_list.querySelectorAll('form')

    forms.forEach(function(form) {
      let filterInput = form.querySelector('input[name="filter"]')
      if (filterInput) {
        filterInput.value = status;
      }
    });

    //create/update form
    var create_filter_input = document.querySelectorAll('input[name="department[filter]"]')
    create_filter_input.forEach(function(input) {
      if (input) {
        input.value = status;
      }
    })
  }
}


