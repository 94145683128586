import Swal from 'sweetalert2'
import { Controller } from "@hotwired/stimulus"
import {post} from "@rails/request.js";
import { Modal } from 'bootstrap';
import $ from 'jquery'

export default class extends Controller {
  static values = {
    message: String,
    url: String,
    method: String,
    modal: String,
    confirm: String
  }

  static targets = ["modal"]

  show(event) {
    const message = this.messageValue;
    event.stopImmediatePropagation()
    event.preventDefault()
    Swal.fire({
      title: message,
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if(result.isConfirmed) {
        post(this.urlValue, {
          responseKind: "turbo-stream"
        })
      }
    })
  }

  confirm(event) {
    const message = this.messageValue;
    event.stopImmediatePropagation()
    event.preventDefault()
    Swal.fire({
      title: message,
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if(result.isConfirmed) {
        post(this.urlValue, {
          responseKind: "turbo-stream"
        }).then((response)=>{
         if ( response.statusCode == 200) {
           Swal.fire(
             'Moved!',
             this.confirmValue,
             'success'
           )
         }
        })
      }
    })
  }

  move(event) {
    const message = this.messageValue;
    event.stopImmediatePropagation()
    event.preventDefault()
    Swal.fire({
      title: message,
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Inactivate',
      confirmButtonText: 'Move',
    }).then((result) => {
      if(result.isConfirmed) {
        let moveModal = new Modal($(this.modalValue));
        console.log(moveModal)
        moveModal.show();
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        post(this.urlValue, {
          responseKind: "turbo-stream"
        })
      }
    })
  }
}


